import { render, staticRenderFns } from "./gameplay.vue?vue&type=template&id=7ffb5670&scoped=true&"
import script from "./gameplay.vue?vue&type=script&lang=js&"
export * from "./gameplay.vue?vue&type=script&lang=js&"
import style0 from "./gameplay.vue?vue&type=style&index=0&id=7ffb5670&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ffb5670",
  null
  
)

export default component.exports