<template>
  <div class="game">
    <van-loading type="spinner" color="#f21313" v-if="!gameShow" />
    <iframe v-if="gameShow" class="iframe" :src="src" frameborder="0"></iframe>
    <div class="back" @click="back">返回</div>
  </div>
</template>

<script>
import { gameUrl } from "@/api/app.js";
export default {
  data() {
    return {
      src: "", //游戏地址
      gameShow: false, //
    };
  },
  created() {
    this.gamePlay();
  },
  methods: {
    back() {
      this.$router.push("/game");
      this.gameShow = false;
    },
    async gamePlay() {
      let res = await this.$Api(gameUrl, {
        game: this.$route.query.id + "",
      });
      this.gameShow = true;
      if (res.code == 200) {
        this.src = res.data.gameURL;
      }
    },
  },
};
</script>
<style scoped>
.game {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.iframe {
  width: 100%;
  height: 100%;
}
.back {
  position: absolute;
  top: 0;
  right: 23%;
  width: 0.8rem;
  height: 0.8rem;
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  line-height: 0.8rem;
  color: #000000;
  transform: rotate(90deg);
}
</style>
